import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useOrder } from '../../context/OrderContext';
import apiService from '../../services/apiService';
import { useCart } from '../../context/CartContext';

const OrderSuccess = () => {
	const { orderData, isOrderCreated, setIsOrderCreated } = useOrder();
	const { fetchCart } = useCart(); // Importer la fonction pour récupérer le panier
	const [orderReference, setOrderReference] = useState(null);
	const [userEmail, setUserEmail] = useState(null);

	useEffect(() => {
		const createOrder = async () => {
			// Vérification que toutes les informations sont disponibles
			const areOrderDetailsReady = orderData &&
				orderData.selectedLivraison &&
				orderData.selectedFacturation &&
				orderData.selectedCarrier &&
				orderData.totalPanier > 0 &&
				orderData.totalCommande > 0;

			if (areOrderDetailsReady && !isOrderCreated) {
				try {
					// Récupération de l'email de l'utilisateur depuis orderData
					const emailApi = orderData.selectedLivraison.utilisateur.email;
					setUserEmail(emailApi);

					// Appel API pour créer la commande
					const response = await apiService.post('commandes', {
						utilisateur: orderData.selectedLivraison.utilisateur["@id"],
						prix_total_commande: orderData.totalCommande.toString(),
						transporteur: orderData.selectedCarrier.carrier["@id"],
						methodeLivraison: orderData.selectedCarrier.methode["@id"],
						frais_livraison: orderData.selectedCarrier.methode.prix.toString(),
						panier: `/api/paniers/${orderData.id_panier}`,
						adresseFacturation: orderData.selectedFacturation["@id"],
						adresseLivraison: orderData.selectedLivraison["@id"],
						total_produits_commande: orderData.totalPanier.toString(),
					});

					// Si la commande est créée avec succès
					setOrderReference(response.reference);
					setIsOrderCreated(true);

					// Supprimer les données de commande du localStorage
					localStorage.removeItem('orderData');
					// Rafraîchir le panier ouvert pour afficher le panier vide
					await fetchCart('ouvert');
				} catch (error) {
					console.error("Erreur lors de la création de la commande :", error);
				}
			} else {
				console.log("Commande déjà créée ou données insuffisantes pour la création.");
			}
		};

		createOrder();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container className="text-center py-4">
			<div className="my-4">
				<h2>COMMANDE {orderReference}</h2>
				<div style={{ fontSize: "4rem", color: "green" }}>✓</div>
				<h3 className="mt-3">Merci de votre commande</h3>
				<p>Un mail récapitulatif a été envoyé à l'adresse suivante :</p>
				<p><strong>{userEmail}</strong></p>
			</div>
		</Container>
	);
};

export default OrderSuccess;
